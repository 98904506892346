
export default {
  name: 'ButtonBurgerMenu',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    open: {
      required: false,
      default: false
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('click')
      const menu = this.$refs.burgerMenuButton
      if (menu.classList.contains('is-open')) {
        menu.classList.remove('is-open')
        menu.classList.add('is-closed')
      } else {
        menu.classList.remove('is-closed')
        menu.classList.add('is-open')
      }
    }
  }
}
